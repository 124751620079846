<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div v-if="notFound" class="col-lg-7">
        <h4 class="text-center">Sertifikat tidak ditemukan</h4>
      </div>
      <div v-else-if="notValid" class="col-lg-7">
        <h4 class="text-center">Sertifikat tidak valid</h4>
      </div>
      <div v-else class="col-lg-12 overflow-auto">
        <vue-html2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1200"
          :filename="certificateName"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="1150px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            
            <!-- Certificate By Id -->
            <template v-if="certificate.training_id == 18">
              <div
                ref="content"
                class="position-relative cert-2 text-center mr-3"
                style="transform: scale(0.9);margin-top: -10px;"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/certificate/sertifikat-pemenang.png"
                    class="w-100"
                    alt=""
                  />
                </div>
                <div class="position-absolute w-100" style="top: 9%;transform: translateX(-50%);left:52%;">
                  <div style="font-size: 3em;">SERTIFIKAT</div>
                  <div class="row font-cert justify-content-center">
                    <div class="col-lg-9">

                      <div>
                        <div>Nomor: {{ certificate.certificate_code }}</div>
                        <div>diberikan kepada :</div>
                      </div>

                      <div style="font-size: 2em;">{{ certificate.name }}</div>

                      <div v-if="certificate.role_detail == 'Peserta'">
                        Atas Keberhasilan Menyelesaikan Tantangan Kegiatan Membaca Buku Itu Menyenangkan
                      </div>

                      <div v-if="certificate.role_detail == 'Partisipan'">
                        Atas Partisipasinya pada Kegiatan Membaca Buku Itu Menyenangkan
                      </div>

                      <div>
                        <span>“Membangun Kebiasan Baik dengan Tantangan 21 Hari Membaca Nyaring”</span>
                      </div>

                      <div class="text-center font-cert mt-1 position-relative">
                        <div>Jakarta, 8 Juni 2023</div>
                        <div style="margin-bottom: 100px">Kepala Pusat Perbukuan,</div>
                        <img
                          src="@/assets/TTD.png"
                          alt="regard"
                          class="position-absolute"
                          style="top:14px;left:5%;right:0;margin:auto;width:190px;transform: rotate(-10deg)"
                        />
                        <div>Supriyatno, S. Pd., M. A.</div>
                        <div>NIP 196804051988121001</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else-if="certificate.training_id == 16">
              <div
                ref="content"
                class="position-relative cert-2 text-center mr-3"
                style="transform: scale(0.9);margin-top: -10px;"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/certificate/sertifikat-novel.png"
                    class="w-100"
                    alt=""
                  />
                </div>
                <div class="position-absolute w-100" style="top: 18%;transform: translateX(-50%);left:52%;">
                  <div style="font-size: 2em;">SERTIFIKAT</div>
                  <div class="row font-cert justify-content-center">
                    <div class="col-lg-9">

                      <div>
                        <div>Nomor: {{ certificate.certificate_code }}</div>
                        <div>diberikan kepada :</div>
                      </div>

                      
                      <div class="my-3">
                        <div style="font-size: 1.8em;">{{ certificate.name }}</div>
                        <span>Atas Partisipasinya sebagai PESERTA pada kegiatan Workshop Penulisan Model Buku Nonteks Jenjang D dan E “Cipta Kreasi Buku Bermutu yang Asyik dan Menarik” pada tanggal 5 – 8 Juni 2023</span>
                      </div>

                      <div class="text-center font-cert mt-4 position-relative">
                        <div>Jakarta, {{ regardDate }}</div>
                        <div style="margin-bottom: 100px">Kepala Pusat Perbukuan,</div>
                        <img
                          src="@/assets/TTD.png"
                          alt="regard"
                          class="position-absolute"
                          style="top:14px;left:5%;right:0;margin:auto;width:190px;transform: rotate(-10deg)"
                        />
                        <div>Supriyatno, S. Pd., M. A.</div>
                        <div>NIP 196804051988121001</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Template Sertifikat Standard -->
            <template v-else>
              <div
                ref="content"
                class="position-relative cert text-center mt-5"
                style="transform: scale(0.9)"
              >
                <div class="position-relative">
                  <img
                    src="@/assets/certificate-template.jpg"
                    class="w-100"
                    alt=""
                  />
                </div>
                <div class="position-absolute w-100 " style="top: 30%;transform: translateX(-50%);left:50%;">
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <div class="font-cert mt-1 mb-3">
                        Nomor : {{ certificate.certificate_code }}
                      </div>
  
                      <div v-if="certificate.training_id == 11">
                        <div class="font-cert mx-auto" style="width: 80%">
                          diberikan kepada:
                        </div>
                      </div>
  
                      <div v-else>
                        <div class="font-cert mx-auto" style="width: 80%">
                          Pusat Perbukuan, Badan Standar, Kurikulum, dan Asesmen
                          Pendidikan <br> Kementerian Pendidikan, Kebudayaan, Riset, dan
                          Teknologi mengucapkan terima kasih <br> kepada:
                        </div>
                      </div>
  
                      <h3 class="my-3 text-primary">{{ certificate.name }}</h3>
                      
                      <div v-if="certificate.training_id == 9">
                        Pelatihan Penyusunan Buku Ajar yang Dilaksanakan oleh Pusat Perbukuan Kementrian Pendidikan Kebudayaan Riset dan Teknologi Bekerja Sama dengan Guru Penggerak Kabupaten Sumbawa Tanggal 9 s.d. 11 September 2022
                      </div>

                      <div v-if="certificate.training_id == 21">
                        Atas Partisipasinya sebagai Peserta pada Kegiatan Lokakarya (<i>Workshop</i>) Penulisan Buku Teks SMK yang dilaksanakan pada Tanggal 12 s.d. 14 September 2023
                      </div>
  
                      <div v-else-if="certificate.training_id == 11">
                        Atas Partisipasinya sebagai {{ certificate.role }} pada Pengembangan Model Buku Nonteks {{ certificate.studentmeta }} Tahun 2022
                        <div>di Pusat Perbukuan, BSKAP, Kemendikbudristek</div>
                      </div>
  
                      <div v-else class="font-cert mx-auto" style="width: 80%">
                        Telah berperan serta dalam Kegiatan
                        <span v-html="trainingTitle"></span>

                        {{
                          trainingStartDate != "18 Mei 2022"
                            ? sessionTraining
                            : ""
                        }}

                        <span v-if="certificate.role != ''">
                          <span v-if="certificate.training_id == 25">dalam kategori <strong>{{ certificate.role }}</strong></span>
                          <span v-else-if="certificate.training_id == 27"><!-- No Show--></span>
                          <span v-else>sebagai <strong>{{ certificate.role }}</strong></span>

                          <span v-if="certificate.training_id == 33 || certificate.training_id == 32 || certificate.training_id == 30 || certificate.training_id == 28 || certificate.training_id == 25 || certificate.training_id == 27"> selama <b>{{ certificate.training_event.duration }} JP</b></span>
                        </span>

                        yang diselenggarakan pada tanggal {{ trainingStartDate }} s.d {{ trainingEndDate }}
                      </div>

                      <div class="float-right font-cert mr-5 mt-5 position-relative">
                        <div>Jakarta, {{ regardDate }}</div>
                        <div style="margin-bottom: 100px">Kepala Pusat Perbukuan,</div>
                        <img
                          src="@/assets/TTD.png"
                          alt="regard"
                          class="position-absolute"
                          style="top:14px;left:-70px;width:210px;transform: rotate(-10deg)"
                        />
                        <div>Supriyatno, S. Pd., M. A.</div>
                        <div>NIP 196804051988121001</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Halaman JP.. -->
            <div
              v-show="checkTrainingID"
              ref="content"
              class="position-relative cert"
              style="transform: scale(0.9);margin-top: -20px"
            >
              <div class="position-relative">
                <img v-show="certificate.training_id == 16" src="@/assets/certificate/sertifikat-novel.png" class="w-100" alt="" />
                <img v-show="certificate.training_id == 9 || certificate.training_id == 25 || certificate.training_id == 27" src="@/assets/certificate-template.jpg" class="w-100" alt="" />
              </div>

              <template v-if="certificate.training_id == 16">
                <div class="position-absolute container" style="top: 25%">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th width="30">No</th>
                              <th>Nama Kegiatan</th>
                              <th>Jumlah Jam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Perjenjangan Buku</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Motivasi Menulis: Cara Mengelola Ide, Tips, dan Trik bagi Penulis</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Dinamika Cerita</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Inspirasi Menulis Komik: Penokohan dan Alur Cerita </td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Standar dan Kaidah Proses Buku Nonteks</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Pra Penulisan</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Unsur Intrinsik, Desain Karakter, dan Environment</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Kebahasaan: Rasa dan Gaya Bahasa, Citraan, dan Pengembangan Cerita</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td colspan="2">Jumlah</td>
                              <td>32 JP</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="certificate.training_id == 9">
                <div class="position-absolute container" style="top: 25%">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <p class="">Materi Pelatihan :</p>
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th width="30">No</th>
                              <th>Materi Pelatihan</th>
                              <th>Jumlah Jam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Hakikat Kurikulum Merdeka</td>
                              <td>6 Jam</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Teknik Penulisan Buku Ajar</td>
                              <td>6 Jam</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Metode Penyusunan Buku Ajar</td>
                              <td>6 Jam</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Penyusunan Draf Buku Ajar</td>
                              <td>6 Jam</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Swa Penyuntingan</td>
                              <td>6 Jam</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>SIBI</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td colspan="2">Total Jam Pelatihan</td>
                              <td>32 Jam</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="certificate.training_id == 21">
                <div class="container" style="transform: scale(.9);margin-top: -50px">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <p class="">Materi Pelatihan :</p>
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th width="30">No</th>
                              <th>Materi Pelatihan</th>
                              <th>Jumlah Jam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Pengenalan Kurikulum Merdeka, Buku Teks, dan Perjenjangan Buku</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Mahir Merumuskan Tujuan Pembelajaran dan Materi Esensial berdasarkan Kurikulum-Capaian Pembelajaran</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Mahir Memilih Kegiatan Pembelajaran dan Asesmen yang Relevan sesuai dengan TP</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Pemenuhan Legalitas dan Norma dalam Penulisan Buku</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Mahir Menerapkan Kaidah Bahasa dan Teknik Penulisan yang Baik dan Benar</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Mahir Menggunakan Visualisasi yang Menarik dan Tepat untuk Pembaca Sasaran secara Berjenjang</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Mahir Menerapkan Standar Anatomi Buku dan Swasunting</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Penerapan Aplikasi Tautan Pendek pada Naskah untuk mengatasi tautan bermasalah</td>
                              <td>2 Jam</td>
                            </tr>
                            <tr>
                              <td colspan="2">Pemaparan Materi</td>
                              <td>16 Jam</td>
                            </tr>
                            <tr>
                              <td colspan="2">Praktik Penyusunan Naskah Buku Teks(kerja mandiri)</td>
                              <td>16 Jam</td>
                            </tr>
                            <tr>
                              <td colspan="2">Total Jam Pelatihan</td>
                              <td><b>32 Jam</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="certificate.training_id == 25">
                <div class="position-absolute container" style="top: 30%;font-size:13px;">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th width="30">No</th>
                              <th>Materi Pelatihan</th>
                              <th>Jumlah Jam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Standar Mutu Buku, Standar Proses dan Kaidah Pemerolehan Naskah, Serta Standar Proses dan Kaidah Penerbitan Buku (Permendikbudristek Nomor 22 Tahun 2022)</td>
                              <td>2 JP</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Pedoman Perjenjangan Buku (Peraturan Kepala BSKAP Nomor 030 Tahun 2022)</td>
                              <td>2 JP</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Kesetaraan gender, disabilitas dan inklusi sosial (GEDSI) dalam buku nonteks</td>
                              <td>2 JP</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Pengantar sastra anak</td>
                              <td>3 JP</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Sastra Anak: Genre dan Format Buku Cerita Anak (Children's Literature: Genre and Format of Children's Books)</td>
                              <td>3 JP</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Materi Khusus: Penulisan Buku Fiksi (Cerita Bergambar)/Nonfiksi Naratif/Puisi/Novel/Komik</td>
                              <td>36 JP</td>
                            </tr>
                            <tr>
                              <td colspan="2">Total Jam Pelatihan</td>
                              <td><b>48 JP</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="certificate.training_id == 27">
                <div class="position-absolute container" style="top: 30%;font-size:13px;">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th width="30">No</th>
                              <th>Materi Pelatihan</th>
                              <th>Jumlah Jam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Pedoman Perjenjangan Buku (Peraturan Kepala BSKAAP No 030/2022)</td>
                              <td>3 JP</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Kesetaraan Gender, Disabilitas, dan Inklusi Sosial (GEDSI) dalam buku nonteks</td>
                              <td>3 JP</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Bagaimana membuat ilustrasi berkesan untuk cerita anak?</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Pengantar Buku Bergambar</td>
                              <td>4 JP</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Dari Teks ke Visual: Ilustrasi dalam Buku Pendidikan</td>
                              <td>8 JP</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Teori Warna</td>
                              <td>6 JP</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Desain Karakter dan Papan Cerita</td>
                              <td>12 JP</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Cetak, Publikasi, Etika Kerja, dan Plagiasi</td>
                              <td>8 JP</td>
                            </tr>
                            <tr>
                              <td colspan="2">Total Jam Pelatihan</td>
                              <td><b>48 JP</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </div>
          </section>
        </vue-html2pdf>
      </div>
      
      <div v-if="!notValid && !notFound" class="mt-3 text-center">
        <button @click="generatePDF()" class="btn btn-primary">
          Unduh Sertifikat
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";
import { BASE_URL } from "@/store";
import moment from "moment";
moment.locale("id");

export default {
  data() {
    return {
      certificate: [],
      notFound: false,
      notValid: false,
    };
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    regardDate: function() {
      return moment(this.certificate.training_event?.end).format("LL");
    },
    trainingStartDate: function() {
      return moment(this.certificate.training_event?.start).format("LL");
    },
    trainingEndDate: function() {
      return moment(this.certificate.training_event?.end).format("LL");
    },
    sessionTraining: function() {
      let session = "";
      
      this.certificate.session == "online" && (session = "daring");
      this.certificate.session == "offline" && (session = "luring");

      return `secara ${session}`;
    },
    trainingTitle: function() {
      return this.certificate.training_event?.title;
    },
    certificateName: function() {
      return `Sertifikat ${this.certificate?.training_event?.title}`
    },
    checkTrainingID: function() {
      return this.certificate.training_id == 9 || this.certificate.training_id == 16 || this.certificate.training_id == 21 || this.certificate.training_id == 25 || this.certificate.training_id == 27
    }
  },
  created() {
    axios
      .post(`${BASE_URL}api/training/ticket/${this.$route.params.id}`)
      .then((res) => {
        // Check if ticket code not valid
        if (res.data.status == "failed") {
          this.notFound = true;
        } else {
          // Get data status ticket
          let statusTicket = res.data.data.status;

          // Check status ticket
          if (statusTicket == "approved" || statusTicket == "attended") {
            this.certificate = res.data.data;
          } else {
            this.notValid = true;
          }
        }
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style scoped>
/* @media screen and (max-width: 992px) {
  .cert {
    transform: scale(0.9);
  }
} */
.font-cert {
  font-size: 0.9em;
}
.cert-2 {
  font-size: 1.2em;
}
</style>
